import _ from "lodash";
import React, { Component } from "react";
import { Loader, Container, Dropdown, Button, Popup } from "semantic-ui-react";
import { Table, Form, FormGroup, Modal, Navbar, Alert } from "react-bootstrap";
import config from "../config/index";
import GoogleMapReact from "google-map-react";
import { connect } from "react-redux";
import moment from "moment";
import {
  getLboxs,
  onUsernameChanged,
  onAccountChanged,
  onPasswordChanged,
  getAreasLbox,
  deleteAreaLbox,
  getLinkPosition,
  getAreaQueue,
} from "../redux/actions";

const AnyReactComponent = ({ name, radio, type }) => (
  <Popup
    content={
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>Nombre: {name}</span>
        <span>Radio: {radio} m</span>
      </div>
    }
    trigger={
      type == 1 ? (
        <img
          style={{ width: 45, height: 45 }}
          src={"http://168.235.83.246/images/pinGeocercaSegura.png"}
        />
      ) : (
        <img
          style={{ width: 45, height: 45 }}
          src={"http://168.235.83.246/images/pinGeocercaInsegura.png"}
        />
      )
    }
  />
);
const LboxMarker = ({ text, type }) => (
  <img
    style={{ width: 45, height: 45 }}
    src={`http://168.235.83.246/images/pinVehiculo.png`}
  />
);

class AreaByVehicle extends Component {
  state = {
    areas: [],
    center: {
      lat: 24.8042,
      lng: -107.431,
    },
    zoom: 5,
    loading: true,
    areasLbox: [],
    showModal: false,
    opciones: [
      { text: "Segura", value: 1 },
      { text: "Insegura", value: 2 },
      { text: "Armado automatico", value: 3},
      { text: "Todas", value: 0 },
    ],
    searchType: { text: "Todas", value: 0 },
    data: "",
    selectedLbox: null,
    lboxPosition: {
      lat: 0,
      lng: 0,
    },
    geofencesToDelete: [],
    deletedGeofences:[],
    openModalToEliminateGeofences:false,
    modalToShowDeletedGeofences:false,
    showModalProgress:false
  };
  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  async componentWillMount() {
    let token = await sessionStorage.getItem("token");
    if (!token) {
      alert("Debes de iniciar sesion");
      this.props.history.push("/#");
    } else {
      let user = JSON.parse(token);
      this.props.onUsernameChanged(user.username);
      this.props.onAccountChanged(user.account);
      this.props.onPasswordChanged(user.password);
      const { account, username, password, getLboxs } = this.props;
      await getLboxs(account, username, password);
      let aux = [];

      if (this.props.lboxs.length > 0) {
        this.props.lboxs.map((lbox) => {
          aux.push({ text: lbox.objectno, value: lbox });
        });
      }
      this.setState({ loading: false, vehicles: aux });
    }
  }
  componentWillUnmount() {
    this.getLboxPosition(true);
        this.getAreaQueue(true);
  }
  async getLboxPosition(flag) {
    if (this.state.selectedLbox) {
      await this.props.getLinkPosition(
        this.props.account,
        this.props.username,
        this.props.password,
        this.state.selectedLbox.objectno
      );

      if (this.props.lboxPosition && this.props.lboxPosition.coordinates) {
        this.setState({ lboxPosition: this.props.lboxPosition.coordinates });
      }
    }
  }
  async onSelectVehicle(lbox) {
    this.setState({ loading: true, selectedLbox: lbox });
    const { account, username, password, getAreasLbox } = this.props;
    await getAreasLbox(
      account,
      username,
      password,
      lbox.objectno,
      lbox.deviceid
    );
    this.setState({
      areasLbox: this.props.areasLbox,
      searchType: { text: "Todas", value: 0 },
      data: "",
      selectedLbox: lbox,
    });
    this.getLboxPosition();
    this.setState({ loading: false });
  }
  filterForText(text) {
    if (text === "") {
      this.setState({ areasLbox: this.props.areasLbox });
    }
    const newArray = this.props.areasLbox.filter((item) => {
      const textData = String(text).toUpperCase();
      const areaname = String(item.nombre).toUpperCase();

      if (areaname.indexOf(textData) !== -1) {
        return areaname.indexOf(textData) > -1;
      }
    });

    this.setState({ areasLbox: newArray, data: text });
  }
  async deleteArea(id) {
    const { account, username, password, deleteAreaLbox, getAreasLbox } =
      this.props;
    await deleteAreaLbox(account, username, password, id);
    if (this.props.message) {
      alert(this.props.message);
    } else {
      this.setState({ loading: true });
      await getAreasLbox(
        account,
        username,
        password,
        this.state.selectedLbox.objectno,
        this.state.selectedLbox.deviceid
      );
      this.setState({ areasLbox: this.props.areasLbox, loading: false });
    }
  }
  getGeofenceTypeLabel(type) {
    switch(type) {
      case "1":
        return "Segura";
      case "2": 
        return "Insegura";
      case "3": 
        return "Armado automatico"
      default:
        return "Sin definir"
    }
  }
  renderAreasTable() {
    if (this.state.loading) {
      return (
        <Container
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flex: 1,
            alignItems: "center",
          }}
        >
          <Loader size="big" active inline="centered">
            Cargando geocercas...
          </Loader>
        </Container>
      );
    }else{
        if (this.state.areasLbox.length > 0) {
          return this.state.areasLbox.map((area) => {
            const typeLabel = this.getGeofenceTypeLabel(area.tipo)
            return (
              <tr>
                <th style={{ width: 30 }}>
                  {" "}
                  <Form.Check
                    type="checkbox"
                    onClick={() => this.handleCheckedField(area)}
                  />
                </th>
                <td>{area.nombre === "" ? area.addrnr : area.nombre}</td>
                <td>{typeLabel}</td>
              </tr>
            );
          });
        } else {
          return (
            <tr>
              <td></td>
              <td>Sin areas.</td>
            </tr>
          );
        }
    }

  }
  renderModalToConfirmElimiateGeofences() {
    return (
      <Modal
        show={this.state.openModalToEliminateGeofences}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => this.setState({ openModalToEliminateGeofences: false })}
      >
        <Modal.Header>
          <h5>Confirmacion de Eliminacion de geocercas</h5>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontWeight: "bold" }}>
            Se eliminaran las siguientes geocercas
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              width: "100%",
              backgroundColor: "white",
              maxHeight: "40vh",
            }}
          >
            <Table responsive>
              <thead style={styles.tableHeader}>
                <tr>
                  <th>vehículo</th>
                  <th>Geocerca</th>
                  <th>Tipo</th>
                </tr>
              </thead>
            <tbody>{this.renderGeofencesToEliminate()}</tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button
            style={{ backgroundColor: "gray", color: "white", width: 100 }}
            fluid
            onClick={() => this.setState({ openModalToEliminateGeofences: false })}
          >
            Cancelar
          </Button>
          <Button
            style={{ backgroundColor: "olive", color: "white", width: 100 }}
            fluid
            onClick={() => this.handleConfirmEliminate()}
            
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
 async handleConfirmEliminate(){
  await  this.handleDeleteSelectedGeofences(this.state.geofencesToDelete)
    this.setState({ openModalToEliminateGeofences: false,geofencesToDelete: [] })
  }
  

  renderGeofencesToEliminate(){
    if (this.state.geofencesToDelete.length > 0) {
        return this.state.geofencesToDelete.map((geofence) => {
          return (
            <tr>
                <td>{geofence.objectno}</td>
              <td>{geofence.nombre === "" ? geofence.addrnr : geofence.nombre}</td>
              <td>{geofence.tipo == 1 ? "Segura" : "Insegura"}</td>
            </tr>
          );
        });
      } else {
        return (
          <tr>
            <td></td>
            <td>ninguna geocerca seleccionada.</td>
            <td></td>
          </tr>
        );
      }
  }
  handleCheckedField(area) {
    let index = _.findIndex(this.state.geofencesToDelete, area);
    if (index >= 0) {
      let aux = this.state.geofencesToDelete.filter(
        (geofence) => geofence != area
      );

      this.setState({ geofencesToDelete: aux });
    } else {
      this.setState({
        geofencesToDelete: [...this.state.geofencesToDelete, area],
      });
    }
  }
  async handleDeleteSelectedGeofences(array){
    this.setState({ loading: true });
    const { account, username, password, deleteAreaLbox, getAreasLbox } =
    this.props;
    let auxDeletedGeofenes=[];
    await array.map(async (obj)=>{ 
    await deleteAreaLbox(account, username, password, obj.id)
    await getAreasLbox(
        account,
        username,
        password,
        this.state.selectedLbox.objectno,
        this.state.selectedLbox.deviceid
      );
      this.setState({ areasLbox: this.props.areasLbox})
    if (this.props.message) {
        auxDeletedGeofenes.push(obj)
    } 
  })

  if (auxDeletedGeofenes > 0) {
    alert('Ocurrio un error, puede que no todas las geocecas, fueran enviadas a eliminar')
  } else {
    alert('Geocecas enviadas a eliminar con exito')
  }

  await this.setState({ areasLbox: this.props.areasLbox, loading: false, deletedGeofences:[auxDeletedGeofenes]});
  this.getAreaQueue();  
}
  renderMarkers() {
    if (this.state.areasLbox.length > 0) {
      return this.state.areasLbox.map((area) => {
        return (
          <AnyReactComponent
            lat={area.latitud}
            lng={area.longitud}
            name={area.nombre}
            radio={area.radio}
            type={area.tipo}
          />
        );
      });
    }
  }
  renderAlert() {
    return (
      <Alert variant="primary">
        Añadiendo geocercas en progreso.
        <Alert.Link onClick={() => this.setState({ showModalProgress: true })}>
          Ver progreso.
        </Alert.Link>
      </Alert>
    );
  }
  renderListOfGeofencesInQueue(){
if (this.props.areaQueue.length > 0) {
    return this.props.areaQueue.map((geofence) => {
      return (
        <tr>
            <td>{geofence.objectno}</td>
          <td>{geofence.addrname1}</td>
          <td>{geofence.status }</td>
        </tr>
      );
    });
  } else {
    return (
      <tr>
        <td></td>
        <td>ninguna geocerca en cola.</td>
        <td></td>
      </tr>
    );
  }
  }
  modaWithInformation() {
    return (
      <Modal
        show={this.state.showModalProgress}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => this.setState({ showModalProgress: false })}
      >
        <Modal.Header>
          <h5>Lista de vehiculos en espera</h5>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              width: "100%",
              backgroundColor: "white",
              maxHeight: "40vh",
            }}
          >
            <Table responsive>
              <thead style={styles.tableHeader}>
                <tr>
                  <th>vehículo</th>
                  <th>Geocerca</th>
                  <th>Estado</th>
                </tr>
              </thead>
            <tbody>{this.renderListOfGeofencesInQueue()}</tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "olive", color: "white", width: 100 }}
            fluid
            onClick={() => this.setState({ showModalProgress: false })}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  async getAreaQueue(flag) {

    await this.props.getAreaQueue(
      this.props.account,
      this.props.username,
      this.props.password
    );
    await this.sleep(30000);
    if (flag) {
    } else {
      this.getAreaQueue();
    }
  }
  render() {
    return (
      
          <div         style={{
          display: "flex",
          width: "100%",
          backgroundColor: "#C9CAD9",
          height: "92.35%",
          flexDirection: "column",
        }}
        >
            {this.props.areaQueue.length > 0 ? this.renderAlert() : <div />} 
            <div style={styles.container}>
            <div style={styles.form}>
          <Form.Label style={{ fontSize: 24 }}>
            Geocercas por vehículo
          </Form.Label>
          <span style={styles.subtitleText}>Seleccione un vehículo:</span>
          <Dropdown
            search
            selection
            options={this.state.vehicles}
            onChange={(e, data) => this.onSelectVehicle(data.value)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxHeight: "70vh",
            }}
          >
            <Form.Group
              style={{
                backgroundColor: config.colors.strongGray,
                padding: 10,
                display: "flex",
                width: "100%",
                marginBottom: 0,
                marginTop: 40,
              }}
            >
              <Form.Control
                style={{ width: "60%", marginRight: 15, height: 40 }}
                type="text"
                placeholder="Buscar geocerca"
                onChange={(e) => this.filterForText(e.target.value)}
              />
              <Dropdown
                selection
                options={this.state.opciones}
                onChange={(e, data) =>
                  this.setState({ searchType: data.value })
                }
                defaultValue={this.state.searchType.value}
              />
            </Form.Group>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                width: "100%",
                backgroundColor: "white",
                height: "70%",
              }}
            >
              <Table responsive>
                <thead>
                  <th style={{ width: 30 }}></th>
                  <th>Nombre</th>
                  <th>Tipo</th>
                </thead>
                <tbody>{this.renderAreasTable()}</tbody>
              </Table>
            </div>
            <Button
              onClick={() => this.setState({ openModalToEliminateGeofences: true })}
              style={{
                backgroundColor: config.colors.green,
                color: "white",
                marginTop: 10,
              }}
              fluid
              disabled={this.state.geofencesToDelete.length <= 0}
            >
              Eliminar geocercas
            </Button>
          </div>
        </div>
        <div style={styles.map}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: config.credentials.apiKeyMap }}
            defaultCenter={this.state.center}
            defaultZoom={this.state.zoom}
          >
            <LboxMarker
              color="blue"
              lat={this.state.lboxPosition.lat}
              lng={this.state.lboxPosition.lng}
            />
            {this.renderMarkers()}
            {this.modaWithInformation()}
          </GoogleMapReact>
        </div>
        {this.renderModalToConfirmElimiateGeofences()}
      </div>
            </div>



       
    );
  }
}

const styles = {
  loader: {
    marginTop: 100,
  },
  container: {
    flexDirection: "row",
    display: "flex",
    height: "100%",
    backgroundColor: config.colors.lightGray,
  },
  form: {
    padding: 10,
    margin: 20,
    width: "30%",
    maxWidth: 400,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  map: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    margin: 20,
    width: "70%",
    backgroundColor: "white",
  },
  searchbar: {
    widthflex: 1,
    flexDirection: "row",
    display: "flex",
    height: 60,
    padding: 10,
    backgroundColor: config.colors.strongGray,
    width: "100%",
  },
  tableHeader: {
    backgroundColor: config.colors.lightGray,
  },
};

const mapStateToProps = (state) => {
  return {
    account: state.auth.account,
    password: state.auth.password,
    username: state.auth.username,
    lboxs: state.lbox.lboxs,
    message: state.lbox.message,
    areasLbox: state.lbox.areasLbox,
    lboxPosition: state.lbox.lboxPosition,
    areaQueue: state.lbox.areaQueue,
  };
};
export default connect(mapStateToProps, {
  getLboxs,
  onUsernameChanged,
  onAccountChanged,
  onPasswordChanged,
  getAreasLbox,
  deleteAreaLbox,
  getLinkPosition,
  getAreaQueue,
})(AreaByVehicle);
