//Archivo para importar los recursos necesarios en la mayoría de los archivos.
export default {
    images: {
        logoInroute: require('../images/logo.png'),
        whiteLogo: require('../images/logoBlanco.png'),
        notification: require('../images/notificacion.png'),
        refresh: require('../images/actualizar.png'),
        lboxLogo: require('../images/LBox_logo.png'),
        lboxBlanco : require('../images/lboxBlanco.png'),
        menu:require('../images/menu.png'),
        construction: require('../images/construccion.png'),
        map: require('../images/mapa.jpg'),
        armado: require('../images/bloquear.png'),
        alarmado: require('../images/armado.png'),
        liberar: require ('../images/destrabar.png'),
        historial: require ('../images/paso-del-tiempo.png'),
        paroMotor: require('../images/potencia.png'),
        resendIcon: require('../images/recargar.png')

    },
    colors:{
        lightGray: "#C9CAD9",
        strongGray: "#454955",
        green: "#9BB800",
        white: "#FFFFFF",
        blue: "#3993DD"
    },
    credentials:{
        apiKeyMap:"AIzaSyBC5mNhMJRTWFC88QLh_3Ijt_VMkoEvwT0"
    },
    appVersion: 'V 0.1.18'
}