import _ from 'lodash';
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Loader, Container, Dropdown, Button, Popup } from 'semantic-ui-react';
import { Table, Form, FormGroup, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    getLboxs,
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    liberarLbox,
    alarmarLbox,
    armarLbox,
    getOutputStatus,
    sendOutputStatus,
    getLinkPosition,
    sendDataLbox,
    resetLinkMessageQueue
} from '../redux/actions';
import config from '../config';
import moment from 'moment';
import timeZone from 'moment-timezone'

const AnyReactComponent = ({ text }) => <Popup content={text} trigger={<img style={{ width: 45, height: 45 }} src={`http://168.235.83.246/images/pinVehiculo.png`} />} />
const options = [
    { key: 1, text: 'Alarmados', value: "Alarmado" },
    { key: 2, text: 'Armados', value: "Armado" },
    { key: 3, text: 'Liberados', value: "Liberado" },
    { key: 4, text: 'Todos', value: "todos" }
]

const ACCOUNTS_WITH_STATUS = [
    "galac comercializadora",
    "logistica-aL1"
];


const ACCOUNTS_WITH_MODO_PRUEBA = [
    "galac comercializadora",
    "transportes fema"
];

class LboxList extends Component {
    state = {
        open: false,
        height: null,
        showModal: false,
        selectedLbox: null,
        loading: true,
        refreshing: false,
        category: "todos",
        filteredLbox: [],
        text: "",
        center: {
            lat: 24.8042,
            lng: -107.431
        },
        zoom: 15,
        cleaningMsgQueue: false,
        ascOrder: true,
        sendingCommand: false
    };
    async componentWillMount() {
        let token = await sessionStorage.getItem('token');
        if (!token) {

            alert('Debes de iniciar sesion')
            this.props.history.push('/#')

        }
        else {


            let user = JSON.parse(token)
            this.props.onUsernameChanged(user.username);
            this.props.onAccountChanged(user.account);
            this.props.onPasswordChanged(user.password);
            const { account, username, password } = this.props;
            await this.props.getLboxs(account, username, password);
            this.setState({ loading: false, filteredLbox: this.props.lboxs });
        }

    }

    renderLoader() {
        return (
            <Container style={styles.loader}>
                <Loader size='massive' active inline='centered'  >Cargando LBox's...</Loader>
            </Container>
        );
    }

    sortData(column) {
        const sortedData = _.sortBy(this.state.filteredLbox, column);
        const isAscOrder = this.state.ascOrder
        this.setState({
            filteredLbox: isAscOrder ? sortedData : sortedData.reverse(),
            ascOrder: !isAscOrder
        })
    }

    filterLbox(category, data) {
        let array = [];
        this.setState({ category: category, text: data })

        if (category === "todos") {
            array = this.filterForText(this.props.lboxs, data);
        } else {
            let auxArray = _.filter(this.props.lboxs, { modo: category });
            array = this.filterForText(auxArray, data);

        }
        this.setState({ filteredLbox: array });

    }
    filterForText(array, text) {
        if (text === "") {
            return array;
        }
        const newArray = array.filter(
            item => {
                const textData = String(text).toUpperCase();
                const vehicle = String(item.objectno).toUpperCase();
                const noSerie = String(item.serieLBOX).toUpperCase();
                if (vehicle.indexOf(textData) !== -1) {
                    return vehicle.indexOf(textData) > -1
                }
                if (noSerie.indexOf(textData) !== -1) {
                    return noSerie.indexOf(textData) > -1
                }
            }
        );
        return newArray;
    };
    async onSelectLbox(lbox) {
        const { getOutputStatus, account, username, password, getLinkPosition } = this.props;
        this.setState({ selectedLbox: lbox })
        await getOutputStatus(lbox.objectno, account, username, password);
        await getLinkPosition(account, username, password, lbox.objectno);
        if (this.props.message) {
            alert(this.props.message);
            this.setState({ center: null })
        }
        else {
            if (this.props.lboxPosition) {
                this.setState({ center: this.props.lboxPosition.coordinates })
            }

        }
    }
    renderRows() {
        if (this.state.filteredLbox.length > 0) {
            return this.state.filteredLbox.map(

                lbox => {

                    let fechaUtc = moment(lbox.fecha, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss UTC');
                    let timez = new Date(fechaUtc).toString();


                    return (

                        <tr onClick={() => this.onSelectLbox(lbox)}>
                            <td>
                                {lbox.objectno}
                            </td>
                            <td>
                                {lbox.serieLBOX}
                            </td>
                            <td>
                                {lbox.modo}
                            </td>
                            <td>
                                {lbox.fecha ? moment(timez).format('DD-MM-YYYY HH:mm') : "Sin reporte"}
                            </td>
                            {
                                ACCOUNTS_WITH_STATUS.includes(this.props.account) ?
                                    <td>
                                        {lbox.vinculado === "1" ? "Conectado" : "Sin conexión"}
                                    </td>
                                    :
                                    <></>
                            }
                            {
                                this.props.account && ACCOUNTS_WITH_MODO_PRUEBA.includes(this.props.account.toLowerCase()) ?
                                    <td>
                                        {lbox.modoPrueba === '0' ? 'Desactivado' : 'Activado'}
                                    </td>
                                    :
                                    <></>
                            }
                        </tr>
                    );
                }
            );
        }
        return (
            <tr>Sin LBox</tr>
        );
    }

    async refreshLboxs() {
        this.setState({ refreshing: true });
        const { account, username, password } = this.props;
        await this.props.getLboxs(account, username, password);
        this.filterLbox(this.state.category, this.state.text);
        if (this.state.selectedLbox) {
            let newSelectedLbox = _.find(this.props.lboxs, { objectno: this.state.selectedLbox.objectno });
            this.setState({ selectedLbox: newSelectedLbox });
        }

        this.setState({ refreshing: false });
    }

    async onButtonClick(action) {
        const { serieLBOX, deviceid, objectno } = this.state.selectedLbox;
        const { account, username, password, sendOutputStatus, sendDataLbox } = this.props;
        this.setState({
            sendingCommand: true
        })
        switch (action) {
            case 'alarmar':
                await sendDataLbox(account, username, password, objectno, deviceid, 'yyy');
                alert(this.props.message);
                break;
            case 'liberar':
                await sendDataLbox(account, username, password, objectno, deviceid, 'ZZZ');
                alert(this.props.message);
                break;
            case 'armar':
                await sendDataLbox(account, username, password, objectno, deviceid, 'YYY');
                alert(this.props.message);
                break;
            case 'apagarMotor':
                await sendOutputStatus(objectno, account, username, password, '0')
                alert(this.props.message);
                break;
            case 'activarModoPrueba':
                await sendDataLbox(account, username, password, objectno, deviceid, 'gG0k');
                alert(this.props.message);
                break;
            case 'desactivarModoPrueba':
                await sendDataLbox(account, username, password, objectno, deviceid, 'gG1k');
                alert(this.props.message);
                break;
            case 'historial':
                this.props.history.push(`/application/historial:${this.state.selectedLbox.objectno}`)
                break;
            default:
                alert('No se ha seleccionado nada');
                break;
        }
        this.setState({
            sendingCommand: false
        })

    }
    async clearLinkMessageQueue() {
        this.setState({ cleaningMsgQueue: true });
        const { account, username, password, resetLinkMessageQueue } = this.props;
        await resetLinkMessageQueue(account, username, password, this.state.selectedLbox.objectno);
        alert(this.props.message);
        this.setState({ cleaningMsgQueue: false });
    }
    renderInformation() {
        if (this.state.selectedLbox === null) {
            return (
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', backgroundColor: 'white', height: '50%', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ fontSize: 25 }}>No se ha seleccionado ningun vehículo</span>
                </div>

            );
        }
        const { objectno, deviceid, modo, fecha, serieLBOX, modoPrueba } = this.state.selectedLbox;
        let fechaUtc = moment(fecha, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss UTC');
        let timez = new Date(fechaUtc).toString();
        return (
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', backgroundColor: 'white', alignItems: 'center', height: '50%', paddingTop: 5 }}>
                <span style={{ width: '100%', fontSize: 30, textAlign: 'left', paddingLeft: 30 }}>{objectno}</span>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', height: '40%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', width: '45%', flexDirection: 'column', height: '90%', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', width: '90%', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                            <span style={styles.subtitleText}>Vehículo:</span>
                            <span style={styles.normalText}>{objectno}</span>
                        </div>
                        <div style={{ display: 'flex', width: '90%', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                            <span style={styles.subtitleText}>Lbox:</span>
                            <span style={styles.normalText}>{serieLBOX}</span>
                        </div>
                        <div style={{ display: 'flex', width: '90%', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                            <span style={styles.subtitleText}>Device id:</span>
                            <span style={styles.normalText}>{deviceid}</span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '45%', flexDirection: 'column', height: '90%', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', width: '90%', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                            <span style={styles.subtitleText}>Modo:</span>
                            <span style={styles.normalText}>{modo}</span>
                        </div>
                        <div style={{ display: 'flex', width: '90%', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                            <span style={styles.subtitleText}>Estado del motor:</span>
                            <span style={styles.normalText}>{this.props.ingition === 0 ? 'Apagado' : 'Encendido'}</span>
                        </div>
                        <div style={{ display: 'flex', width: '90%', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                            <span style={styles.subtitleText}>Último reporte:</span>
                            <span style={styles.normalText}>{moment(timez).format('DD-MM-YYYY HH:mm')}</span>
                        </div>
                    </div>

                </div>
                <div style={{ display: 'flex', marginTop: 15, marginBottom: 10, flexDirection: 'row', width: '90%' }}>
                    <span style={{ display: 'flex', color: '#909090', marginRight: 10, textAlign: 'right' }}>Dirección:</span>
                    <span style={{ display: 'flex', justifyContent: 'flex-start', textAlign: 'left' }}>{this.props.lboxPosition ? this.props.lboxPosition.address : ''}</span>
                </div>
                <div style={{ display: 'flex', marginTop: 15, marginBottom: 10, flexDirection: 'row', justifyContent: 'space-between', width: '90%' }}>

                    <Button onClick={() => this.onButtonClick('alarmar')} disabled={modo === "Alarmado" || this.state.sendingCommand} style={{ display: 'flex', width: '15%', flexDirection: 'column', backgroundColor: 'red', color: "white", justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <img src={config.images.alarmado} style={{ display: 'flex', width: '90%' }} />
                        Alarmar
                    </Button>
                    <Button onClick={() => this.onButtonClick('armar')} disabled={modo === "Armado" || this.state.sendingCommand} style={{ display: 'flex', width: '15%', flexDirection: 'column', backgroundColor: '#E6CA31', color: "white", justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <img src={config.images.armado} style={{ display: 'flex', width: '90%' }} />
                        Armar
                    </Button>
                    <Button onClick={() => this.onButtonClick('liberar')} disabled={modo === "Liberado" || this.state.sendingCommand} style={{ display: 'flex', width: '15%', flexDirection: 'column', backgroundColor: '#348B34', color: "white", justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <img src={config.images.liberar} style={{ display: 'flex', width: '90%' }} />
                        Liberar
                    </Button>
                    <Button loading={this.state.cleaningMsgQueue} onClick={() => this.clearLinkMessageQueue()} style={{ display: 'flex', width: '15%', flexDirection: 'column', backgroundColor: '#37a2ef', color: "white", justifyContent: 'space-evenly', alignItems: 'center' }}>
                        Limpiar cola de msj
                    </Button>
                    <Button onClick={() => this.onButtonClick('historial')} style={{ display: 'flex', width: '15%', flexDirection: 'column', backgroundColor: '#2478AC', color: "white", justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <img src={config.images.historial} style={{ display: 'flex', width: '90%' }} />
                        Historial
                    </Button>
                    
                    {
                        this.props.account && ACCOUNTS_WITH_MODO_PRUEBA.includes(this.props.account.toLowerCase()) ?
                        modoPrueba === '0' ?
                            <Button disabled={this.state.sendingCommand} onClick={() => this.onButtonClick('activarModoPrueba')} style={{ display: 'flex', width: '15%', flexDirection: 'column', backgroundColor: '#CF5C36', color: "white", justifyContent: 'space-evenly', alignItems: 'center' }}>
                                Activar modo prueba
                            </Button> :
                            <Button disabled={this.state.sendingCommand} onClick={() => this.onButtonClick('desactivarModoPrueba')} style={{ display: 'flex', width: '15%', flexDirection: 'column', backgroundColor: '#CF5C36', color: "white", justifyContent: 'space-evenly', alignItems: 'center' }}>
                                Desactivar modo prueba
                            </Button>
                            :
                            <></>
                    }
                </div>

            </div>

        );
    }
    renderMap() {
        return (
            <div style={styles.map}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: config.credentials.apiKeyMap }}
                    center={this.state.center}
                    defaultZoom={this.state.zoom}
                >
                    <AnyReactComponent
                        lat={this.props.lboxPosition ? this.props.lboxPosition.coordinates.lat : 0}
                        lng={this.props.lboxPosition ? this.props.lboxPosition.coordinates.lng : 0}
                        text={this.state.selectedLbox ? this.state.selectedLbox.objectno : ''} />
                </GoogleMapReact>
            </div>
        );
    }
    render() {
        const OrderIcon = () => <img alt='order button' style={{ width: 15, height: 15, marginRight: 5 }} src={'http://168.235.83.246/images/ordenar.png'} />;
        return (
            <div style={{ display: 'flex', backgroundColor: '#C9CAD9', height: '92.35%', padding: 15, justifyContent: 'space-evenly' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }} >
                    <div style={{ display: 'flex', width: '100%', padding: 5, backgroundColor: '#717188', flexDirection: 'row' }} >
                        <Form style={styles.searchbarContainer} inline>
                            <FormGroup controlId='formHorizontalSearchBar'>
                                <Form.Control type="text" placeholder="Nombre, uid, ECO..." onChange={(e) => this.filterLbox(this.state.category, e.target.value)} value={this.state.text} />
                            </FormGroup>
                        </Form>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '65%', justifyContent: 'flex-end' }}>
                            <Dropdown value={this.state.category} defaultValue={this.state.category} options={options} selection style={styles.dropdown} onChange={(e, data) => this.filterLbox(data.value, this.state.text)} />
                            <Button loading={this.state.refreshing} style={{ backgroundColor: config.colors.green, color: 'white' }} onClick={() => this.refreshLboxs()} >
                                <img style={{ width: 12, height: 12, marginRight: 5 }} src={'http://168.235.83.246/images/refresh.png'} />
                                Actualizar
                            </Button>
                        </div>

                    </div>
                    <div style={{ display: 'flex', height: '90%', width: '100%' }}>
                        <Table striped bordered responsive hover style={{ boxShadow: '1px 3px 1px #9E9E9E', width: '100%' }}>
                            <thead style={{ backgroundColor: '#E2DDDD' }}>
                                <th onClick={() => this.sortData('objectno')}>
                                    <OrderIcon /> Vehículo
                                </th>
                                <th onClick={() => this.sortData('serieLBOX')}>
                                    <OrderIcon /> LBox
                                </th>
                                <th onClick={() => this.sortData('modo')}>
                                    <OrderIcon /> Estado
                                </th>
                                <th onClick={() => this.sortData('fecha')}>
                                    <OrderIcon /> Fecha de último reporte
                                </th>
                                {
                                    ACCOUNTS_WITH_STATUS.includes(this.props.account) ?
                                        <th onClick={() => this.sortData('vinculado')} >
                                            <OrderIcon /> Estado Lbox
                                        </th>
                                        :
                                        <></>
                                }
                                {
                                   this.props.account && ACCOUNTS_WITH_MODO_PRUEBA.includes(this.props.account.toLowerCase()) ?
                                        <th onClick={() => this.sortData('modoPrueba')} >
                                            <OrderIcon />  Modo Prueba
                                        </th>
                                        :
                                        <></>
                                }
                            </thead>
                            <tbody style={{ backgroundColor: 'white' }}>
                                {this.renderRows()}
                            </tbody>
                        </Table>

                    </div>

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '45%', justifyContent: 'space-between', alignItems: 'center' }}>
                    {this.renderInformation()}
                    {this.renderMap()}
                </div>
            </div>
        );

    }
}
const styles = {
    body: {

    },
    loader: {
        gridColumnStart: 2,
        gridColumnEnd: 3,
        gridRowStart: 2,
        gridRowEnd: 3,
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        width: '100%'
    },
    searchbarContainer: {
        width: '35%',
        display: 'flex'
    },
    dropdown: {
        backgroundColor: config.colors.blue,
        color: 'white',
        marginRight: 5,
        width: 50
    },
    subtitleText: {
        width: 120,
        color: '#909090',
        textAlign: 'right',
        marginRight: 10
    },
    map: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        margin: 20,
        width: '100%',
        backgroundColor: 'white'
    },
    normalText: {
        display: 'flex',
        width: '60%'
    }
};
const mapStateToProps = state => {
    return {
        account: state.auth.account,
        password: state.auth.password,
        username: state.auth.username,
        lboxs: state.lbox.lboxs,
        message: state.lbox.message,
        ingition: state.lbox.ingition,
        lboxPosition: state.lbox.lboxPosition
    };
}
export default connect(mapStateToProps, {
    getLboxs,
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    liberarLbox,
    alarmarLbox,
    armarLbox,
    getOutputStatus,
    sendOutputStatus,
    getLinkPosition,
    sendDataLbox,
    resetLinkMessageQueue
})(LboxList);